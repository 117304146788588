import React, { useContext, useEffect, useState } from "react"
import { WishlistContext } from "../context/wishlistContext"
import { Button, ButtonDisabled } from "./button"

const AddToWishlist = ({ product, item, variant }) => {
  const uid = `${item.uid}-${product.originalId}-${variant.id}`
  const { wishlist, setWishlist } = useContext(WishlistContext)
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    setSelected(wishlist.some(p => p.uid === uid))
  })

  function addProduct(product, item, variant) {
    const a = [...wishlist]
    if (!a.some(product => product.uid === uid)) {
      const p = {
        product: product,
        item: item,
        variant: variant,
        quantity: 1,
        uid: uid,
      }
      a.push(p)
      setWishlist(a)
    }
  }

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist))

    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push("event", "added_product");
    }

  }, [wishlist])

  return (
    <>
      {selected ? (
        <ButtonDisabled>Aggiunto</ButtonDisabled>
      ) : (
        <Button onClick={() => addProduct(product, item, variant)}>
          Aggiungi alla wishlist
        </Button>
      )}
    </>
  )
}

export default AddToWishlist
