import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import Img from "gatsby-image"
import GetProductsByCategoryId from "../utils/getProductByCategoryId"
import ProductBreadcrumbs from "../components/productBreadcrumbs"
import RelatedProducts from "../components/relatedProducts"
import placeholder from "../images/product-placeholder.jpg"
import variantPlaceholder from "../images/variant-placeholder.jpg"
import { BodySmall, Display, Lead } from "../components/typography"
import DesignTokens from "../components/designTokens"
import {
  LinkButtonSecondary,
  ExternalLinkButton,
  ExternalLinkButtonSecondary,
  ButtonGroup,
} from "../components/button"
import RichContentTextBlock from "../components/richContentTextBlock"
import AddToWishlist from "../components/addToWishlist"
import ItemDetails from "../components/itemDetails"

const ProductPage = ({ data: { product }, pageContext }) => {
  const [selectedItem, setSelectedItem] = useState(product.items[0])
  const [selectedVariant, setSelectedVariant] = useState(
    selectedItem.variants.length > 0 ? selectedItem.variants[0] : []
  )

  let relatedProducts = GetProductsByCategoryId(
    product.categories[0].originalId
  ).filter(reletedProduct => reletedProduct.originalId !== product.originalId)

  useEffect(() => {
    if (selectedItem.variants.length > 0) {
      setSelectedVariant(selectedItem.variants[0])
    }
  }, [selectedItem])
  return (
    <Layout>
      <SEO title={product.name} />
      <Wrapper>
        <ProductBreadcrumbs product={product} />
        <Container>
          <Product>
            <Row justify="center">
              <Col sm={12} md={6} lg={5} style={{ marginBottom: "1rem" }}>
                {selectedItem.image ? (
                  <Img fluid={selectedItem.image.fluid} />
                ) : (
                  <img src={placeholder} />
                )}
              </Col>
              <Col sm={12} md={6} lg={5}>
                <Details>
                  <Display>{product.name}</Display>
                  {product.description && (
                    <RichContentTextBlock html={product.description} />
                  )}
                  <section>
                    <Lead>Prodotto</Lead>
                    <ItemsList>
                      {product.items.map(item => (
                        <Item
                          key={item.id}
                          onClick={() => setSelectedItem(item)}
                          className={
                            item.id === selectedItem.id ? "selected" : null
                          }
                        >
                          <ItemDetails item={item} />
                        </Item>
                      ))}
                    </ItemsList>
                  </section>
                  {selectedItem.variants.length > 0 && (
                    <section>
                      <Lead>Finitura</Lead>
                      <VariantsList>
                        {selectedItem.variants.map(variant => (
                          <Variant
                            key={variant.id}
                            onClick={() => setSelectedVariant(variant)}
                            className={
                              variant.id === selectedVariant.id
                                ? "selected"
                                : null
                            }
                          >
                            {variant.image ? (
                              <Img fluid={variant.image.fluid} />
                            ) : (
                              <img src={variantPlaceholder} />
                            )}
                          </Variant>
                        ))}
                      </VariantsList>
                      <BodySmall>{selectedVariant.name}</BodySmall>
                    </section>
                  )}
                  <ButtonGroup>
                    <AddToWishlist
                      product={product}
                      item={selectedItem}
                      variant={selectedVariant}
                    />
                    {product.productSheet && (
                      <ExternalLinkButtonSecondary
                        href={product.productSheet.url}
                        target="blank"
                        rel="noopener nofollow"
                      >
                        Scheda prodotto
                      </ExternalLinkButtonSecondary>
                    )}
                  </ButtonGroup>
                </Details>
              </Col>
            </Row>
          </Product>
        </Container>
        {relatedProducts.length > 0 && (
          <RelatedProducts relatedProducts={relatedProducts} />
        )}
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    & > * {
      margin-bottom: 1rem;
    }
  }
`
const Product = styled.div``
const Details = styled.div`
  & > * {
    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ItemsList = styled.ul`
  margin: 0;
  border-top: 1px solid #eee;
  margin-top: 1rem;
  padding-top: 1rem;
  list-style-type: none;
`

const Item = styled.li`
  cursor: pointer;
  margin-bottom: 0;
  &.selected,
  &:hover {
    background-color: #eee;
  }
`

const VariantsList = styled.ul`
  margin: 0;
  display: flex;
  flex: row;
  flex-wrap: wrap;
  border-top: 1px solid #eee;
  margin-top: 1rem;
  padding-top: 1rem;
`

const Variant = styled.li`
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin: 0 1rem 1rem 0;
  border-radius: 1000px;
  overflow: hidden;
  border: 3px solid white;
  &.selected,
  &:hover {
    border: 3px solid ${DesignTokens.colors.primary[500]};
  }
  img {
    display: block;
  }
`

export default ProductPage

export const query = graphql`
  query ProductQuery($slug: String!) {
    product: datoCmsProduct(slug: { eq: $slug }) {
      name
      originalId
      description
      productSheet {
        url
      }
      items {
        image {
          fluid(maxWidth: 768, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        uid
        size
        weight
        id
        variants {
          id
          name
          image {
            fluid(maxWidth: 100, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      categories {
        name
        originalId
        slug
        treeParent {
          originalId
          name
          slug
          treeParent {
            name
            slug
            originalId
          }
        }
      }
    }
  }
`
