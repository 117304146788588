import { useStaticQuery, graphql } from "gatsby"

export const useProducts = () => {
  const menu = useStaticQuery(graphql`
    query ReferenceQuery {
      allDatoCmsProduct(
        filter: { locale: { eq: "en" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          slug
          originalId
          categories {
            name
            originalId
            treeParent {
              originalId
              name
              treeParent {
                name
                originalId
              }
            }
          }
          items {
            image {
              fluid(maxWidth: 768, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            id
          }
        }
      }
    }
  `)
  return menu.allDatoCmsProduct.nodes
}
