import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import ProductThumb from "./productThumb"
import { Heading } from "./typography"

const RelatedProducts = ({ relatedProducts }) => {
  // console.log(relatedProducts)
  return (
    <Wrapper>
      <Container>
        <Row justify="center">
          <CustomCol sm={12} lg={10}>
            <Heading>Scopri altri prodotti simili</Heading>
            <Row gutterWidth={0}>
              {relatedProducts.map(relatedProduct => (
                <Col xs={12} sm={4} lg={3} key={relatedProduct.originalId}>
                  <ProductThumb product={relatedProduct} />
                </Col>
              ))}
            </Row>
          </CustomCol>
        </Row>
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  text-align: center;
`

const CustomCol = styled(Col)`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default RelatedProducts
