import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-grid-system"

const ProductBreadcrumbs = ({ product }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          <ul>
            {product.categories[0].treeParent &&
              product.categories[0].treeParent.treeParent && (
                <li>
                  <Link
                    to={`/${product.categories[0].treeParent.treeParent.slug}/`}
                  >
                    {product.categories[0].treeParent.treeParent.name}
                  </Link>
                </li>
              )}
            {product.categories[0].treeParent && (
              <>
                {product.categories[0].treeParent.treeParent ? (
                  <>
                    <li>
                      <Link
                        to={`/${product.categories[0].treeParent.treeParent.slug}/${product.categories[0].treeParent.slug}/`}
                      >
                        {product.categories[0].treeParent.name}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${product.categories[0].treeParent.treeParent.slug}/${product.categories[0].treeParent.slug}/${product.categories[0].slug}/`}
                      >
                        {product.categories[0].name}
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to={`/${product.categories[0].treeParent.slug}/`}>
                        {product.categories[0].treeParent.name}
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        to={`/${product.categories[0].treeParent.slug}/${product.categories[0].slug}/`}
                      >
                        {product.categories[0].name}
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}
            <li>{product.name}</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  padding-top: 3rem;
  @media screen and (max-width: 767px) {
    padding-top: 1rem;
  }

  ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    list-style: none;
    justify-content: center;
    align-content: center;
    & > * {
      font-size: 14px;
      margin-right: 0.75rem;
      &::after {
        content: "›";
        margin-left: 0.75rem;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }
`

export default ProductBreadcrumbs
