import React from "react"
import styled from "styled-components"
import { BodySmall } from "./typography"

const ItemDetails = ({ item }) => (
  <Wrapper>
    <BodySmall>Cod.Art {item.uid}</BodySmall>
    <BodySmall>{item.size}</BodySmall>
    {item.weight && <BodySmall>{item.weight} Kg</BodySmall>}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  text-transform: uppercase;
  margin: 0;
  & > * {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

export default ItemDetails
