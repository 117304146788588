import { useProducts } from "../hooks/useProducts"

function GetProductsByCategoryId(categoryId) {
  let categoryProducts = useProducts().filter(product =>
    isSameCategory(product)
  )

  function createArray(product) {
    let categoryIds = []
    product.categories.forEach(category => {
      categoryIds.push(category.originalId)
      categoryIds.push(category.treeParent.originalId)
      if (category.treeParent.treeParent) {
        categoryIds.push(category.treeParent.treeParent.originalId)
      }
    })
    return categoryIds
  }
  
  function isSameCategory(product) {
    const sameCategory = (id) => id === categoryId
    return createArray(product).some(sameCategory)
  }
  return categoryProducts
}

export default GetProductsByCategoryId
